:root {
  --dark-background-color: #54595f;
  --toolbar-background-color: #393d3f;
  --primary-blue-color: #00a4b1;
  --secondary-orange-color: #ff6328;

  --map-color-1: #F4F4F2;
}

html {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  font-family: 'ballinger', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: var(--toolbar-background-color);
}

#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom, 0);
}

#toolbar .hintIcon, #toolbar .containerIcon, #toolbar .bookingIcon {
  fill: white;
}

#toolbar .Mui-selected .hintIcon, #toolbar .Mui-selected .containerIcon, #toolbar .Mui-selected .bookingIcon {
  fill: var(--secondary-orange-color);
}
